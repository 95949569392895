import React from "react"

const ArrowLeftIcon = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 12 12">
      <path d="M6.53 11.53a.75.75 0 0 1-1.06 0l-5-5a.75.75 0 0 1 0-1.06l5-5a.75.75 0 0 1 1.06 1.06L2.81 5.75H11a.75.75 0 0 1 0 1.5H2.81l3.72 3.72a.75.75 0 0 1 0 1.06Z" />
    </svg>
  )
}

export default ArrowLeftIcon

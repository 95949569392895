import React from "react"

const ArrowRightIcon = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 12 12">
      <path d="M5.47.47a.75.75 0 0 1 1.06 0l5 5a.75.75 0 0 1 0 1.06l-5 5a.75.75 0 0 1-1.06-1.06l3.72-3.72H1a.75.75 0 0 1 0-1.5h8.19L5.47 1.53a.75.75 0 0 1 0-1.06Z" />
    </svg>
  )
}

export default ArrowRightIcon

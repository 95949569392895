import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ArticleSlices from "../components/News/ArticleSlices.jsx"
import ArticlesGrid from "../components/News/ArticlesGrid.jsx"
import Share from "../components/News/Share.jsx"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import ArticleFooter from "../components/News/ArticleFooter.jsx"
import ArrowLeftIcon from "../components/SVG/ArrowLeftIcon.jsx"

const Article = ({ data }) => {
  const {
    date,
    title,
    read_time,
    hero_image,
    meta_title,
    subheading,
    intro,
    meta_description,
    body,
  } = data.prismicArticle.data

  const { last_publication_date } = data.prismicArticle
  const category =
    data.prismicArticle.data?.category?.document?.data?.category || ""

  const relatedArticles =
    data.relatedArticles.nodes.length > 0
      ? data.relatedArticles.nodes
      : data.allArticles.nodes

  return (
    <Layout>
      <Seo title={meta_title || title} description={meta_description} />

      <div className="mx-auto px-gutter lg:max-w-2xl xl:max-w-4xl">
        <Link
          className="flex items-center space-x-2 text-coral-pink lg:col-start-3"
          to="/news"
        >
          <div className="flex h-5 w-5 items-center justify-center bg-current">
            <ArrowLeftIcon className="h-3 w-3 fill-cloud-white" />
          </div>
          <p className="font-semibold uppercase">Return to News</p>
        </Link>
        {title && (
          <h1 className="mt-8  text-5xl font-light leading-none md:leading-tight">
            {title}
          </h1>
        )}

        <div className="mb-4 mt-12 flex space-x-11">
          <p>{date || last_publication_date}</p>
          {category && <p>{category}</p>}
          {read_time && <p>{read_time} min read</p>}
          {/* Author to be added */}
        </div>

        {hero_image && (
          <div>
            <GatsbyImage
              className="h-full w-full"
              objectFit="contain"
              image={getImage(hero_image.gatsbyImageData)}
              alt={hero_image.alt || ""}
            />
            {hero_image.alt && (
              <p className="pt-2 text-right text-xs">{hero_image.alt}</p>
            )}
          </div>
        )}
        {subheading && (
          <h2 className="mt-10 text-4xl font-light leading-none">
            {subheading}
          </h2>
        )}
        {intro && <h3 className="mt-10 text-2xl leading-snug">{intro}</h3>}

        <ArticleSlices body={body} />
        <Share />
      </div>
      <div className="mx-auto mt-32 max-w-6xl px-gutter">
        <h2 className="text-4xl font-bold uppercase">
          {data.relatedArticles.nodes.length > 0
            ? "Related Articles"
            : "Other Articles"}
        </h2>
        <ArticlesGrid articles={relatedArticles} />
      </div>
      <ArticleFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query MyQuery($uid: String, $category: ID) {
    prismicArticle(uid: { eq: $uid }) {
      tags
      last_publication_date(formatString: "MMMM DD, YYYY")
      data {
        title
        subheading
        intro
        read_time
        hero_image {
          gatsbyImageData
          alt
        }
        meta_title
        meta_description
        body {
          ... on PrismicArticleDataBodyRichText {
            id
            slice_type
            primary {
              content {
                richText
              }
            }
          }
          ... on PrismicArticleDataBodyImageAndText {
            id
            slice_type
            primary {
              image {
                gatsbyImageData
                alt
              }
              content {
                richText
              }
            }
          }
          ... on PrismicArticleDataBodyImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData
                alt
              }
            }
          }
        }
        category {
          document {
            ... on PrismicArticleCategory {
              id
              data {
                category
              }
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
      }
    }
    relatedArticles: allPrismicArticle(
      filter: {
        uid: { ne: $uid }
        data: { category: { id: { eq: $category } } }
      }
      limit: 3
      sort: { fields: data___date, order: DESC }
    ) {
      nodes {
        id
        url
        last_publication_date(formatString: "MMMM DD, YYYY")
        data {
          category {
            id
          }
          title
          card_image {
            alt
            gatsbyImageData
          }
          category {
            document {
              ... on PrismicArticleCategory {
                id
                data {
                  category
                }
              }
            }
          }
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allArticles: allPrismicArticle(
      limit: 3
      sort: { fields: data___date, order: DESC }
    ) {
      nodes {
        id
        url
        last_publication_date(formatString: "MMMM DD, YYYY")
        data {
          category {
            id
          }
          title
          card_image {
            alt
            gatsbyImageData
          }

          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`

import * as React from "react"
import FacebookIcon from "../SVG/FacebookIcon"
import TwitterIcon from "../SVG/TwitterIcon"
import LinkedinIcon from "../SVG/LinkedinIcon"
import CopyIcon from "../SVG/CopyIcon"

const Share = () => {
  const [url, setUrl] = React.useState("")
  const [showFeedback, setShowFeedback] = React.useState(false)

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setUrl(window.location.href)
    }
  }, [])

  const handleCopy = e => {
    e.preventDefault()
    if (typeof navigator !== "undefined") {
      navigator.clipboard.writeText(url)
    }
    setShowFeedback(true)
    setTimeout(() => {
      setShowFeedback(false)
    }, 5000)
    return false
  }

  return (
    <div className="mt-12">
      <p className="text-sm font-bold uppercase">Share</p>
      <div className="mt-8 flex h-8 space-x-8">
        <a
          className="h-5 w-5"
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            url
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedinIcon />
        </a>
        <a
          className="h-5 w-5"
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            url
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon />
        </a>
        <a
          className="h-5 w-5"
          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
            url
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon />
        </a>
        <a className="h-5 w-5 cursor-pointer" href="#" onClick={handleCopy}>
          {showFeedback ? <p>Copied</p> : <CopyIcon />}
        </a>
      </div>
    </div>
  )
}

export default Share

import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ArrowRightIcon from "../SVG/ArrowRightIcon"

const ArticleCard = ({
  date,
  title,
  image,
  link,
  category,
  last_publication_date,
}) => {
  return (
    <div className="flex h-full flex-col">
      <Link to={`/news${link}`}>
        <GatsbyImage
          className="aspect-w-3 aspect-h-2 h-full w-full"
          objectFit="cover"
          sizes="(min-width: 1024px) 336px, 100vw"
          image={getImage(image.gatsbyImageData)}
          alt={image.alt || ""}
        />
      </Link>
      <Link to={`/news${link}`}>
        <div className="pt-4 pb-7">
          <div className="flex space-x-3">
            {/* Use last_publication_date if no date specified */}
            <p>{date || last_publication_date}</p>
            {category && <p>|</p>}
            {category && <p>{category}</p>}
          </div>
          {title && (
            <h3 className="w-11/12 pt-2 text-xl font-medium leading-tight">
              {title}
            </h3>
          )}
        </div>
      </Link>
      <div className="mt-auto">
        <Link to={`/news${link}`}>
          <div className="flex items-center space-x-2 text-coral-pink">
            <div className="flex h-5 w-5 items-center justify-center bg-current">
              <ArrowRightIcon className="h-3 w-3 fill-cloud-white" />
            </div>
            <p className="font-semibold uppercase">Read More</p>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default ArticleCard

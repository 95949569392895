import React from "react"
import ArticleCard from "./ArticleCard"

const ArticlesGrid = ({ articles }) => {
  return (
    <div>
      <div className="mt-12 grid gap-x-8 gap-y-16 lg:grid-cols-3">
        {articles.map(article => {
          const { title, card_image, date } = article.data
          const { url, last_publication_date, id } = article
          let category = ""
          if (
            article.data.category &&
            article.data.category.document &&
            article.data.category.document.data
          ) {
            category = article.data.category.document.data.category
          }
          return (
            <ArticleCard
              key={id}
              date={date}
              last_publication_date={last_publication_date}
              title={title}
              image={card_image}
              link={url}
              category={category}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ArticlesGrid

import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"

const ImageAndText = ({ image, text }) => {
  return (
    <div className="space-y-2 md:flex md:space-x-5">
      <div className="w-full md:w-1/2">
        <GatsbyImage
          className="h-full max-h-screen w-full lg:max-h-108"
          objectFit="cover"
          image={getImage(image.gatsbyImageData)}
          alt={image.alt || ""}
        />

        {image.alt && <p className="pt-2 text-right text-xs">{image.alt}</p>}
      </div>
      <div className="rte rte--article w-full md:w-1/2">
        <PrismicRichText field={text} />
      </div>
    </div>
  )
}

export default ImageAndText

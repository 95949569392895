import React from "react"

const FacebookIcon = () => {
  return (
    <svg
      className="h-full w-full"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 10C20 4.4775 15.5225 0 10 0C4.4775 0 0 4.4775 0 10C0 14.9917 3.65667 19.1283 8.4375 19.8783V12.8908H5.89833V9.99917H8.4375V7.7975C8.4375 5.29167 9.93083 3.90667 12.215 3.90667C13.3083 3.90667 14.4533 4.1025 14.4533 4.1025V6.56333H13.1917C11.9492 6.56333 11.5617 7.33417 11.5617 8.125V10H14.335L13.8917 12.8917H11.5617V19.8792C16.3433 19.1283 20 14.9908 20 10Z"
        fill="black"
      />
    </svg>
  )
}

export default FacebookIcon

import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Image = ({ image }) => {
  return (
    <div className="w-full">
      <GatsbyImage
        className="max-h-200 h-full w-full"
        objectFit="cover"
        image={getImage(image.gatsbyImageData)}
        alt={image.alt || ""}
      />
      {image.alt && <p className="pt-2 text-right text-xs">{image.alt}</p>}
    </div>
  )
}

export default Image

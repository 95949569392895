import React from "react"
import { Link } from "gatsby"
import ArrowRightIcon from "../SVG/ArrowRightIcon.jsx"

const ArticleFooter = () => {
  return (
    <div className="mt-20 bg-land-brown bg-opacity-10">
      <div className="mx-auto max-w-6xl px-gutter ">
        <div className="grid py-16 lg:grid-cols-3 lg:gap-x-8">
          <Link
            className="flex items-center space-x-2 text-coral-pink lg:col-start-3"
            to="/news"
          >
            <div className="flex h-5 w-5 items-center justify-center bg-current">
              <ArrowRightIcon className="h-3 w-3 fill-cloud-white" />
            </div>
            <p className="font-semibold uppercase">See more articles</p>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ArticleFooter

import React from "react"

const CopyIcon = () => {
  return (
    <svg
      className="h-full w-full"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1124 6.50303C9.71545 6.8972 9.71545 7.53836 10.1124 7.93352C11.3005 9.11702 11.3005 11.0425 10.1124 12.2262L7.23719 15.0873C6.08598 16.2332 4.07711 16.2332 2.92577 15.0873C2.35017 14.5143 2.0326 13.7526 2.0326 12.9415C2.0326 12.1304 2.35019 11.3687 2.92577 10.7957C3.32275 10.4015 3.32275 9.76038 2.92577 9.36522C2.5288 8.97006 1.8857 8.97006 1.48872 9.36522C0.529046 10.3194 0 11.5899 0 12.9416C0 14.2933 0.529078 15.5634 1.48872 16.518C2.44749 17.4733 3.72387 17.9999 5.08152 17.9999C6.43916 17.9999 7.71541 17.4732 8.67431 16.518L11.5495 13.6569C13.5295 11.6849 13.5295 8.4753 11.5495 6.50316C11.1526 6.108 10.5096 6.108 10.1125 6.50316L10.1124 6.50303Z"
        fill="black"
      />
      <path
        d="M16.5116 1.48191C15.5529 0.526626 14.2766 0 12.9188 0C11.561 0 10.2849 0.526658 9.32601 1.48191L6.45076 4.343C4.47084 6.31497 4.47084 9.52456 6.45076 11.4967C6.64932 11.6943 6.90933 11.7931 7.16935 11.7931C7.42937 11.7931 7.68938 11.6943 7.88795 11.4967C8.28493 11.1025 8.28493 10.4614 7.88795 10.0662C6.69987 8.88271 6.69987 6.95727 7.88795 5.77355L10.7632 2.91246C11.9144 1.76651 13.9233 1.76651 15.0746 2.91246C15.6502 3.48543 15.9678 4.24718 15.9678 5.05824C15.9678 5.86931 15.6502 6.63106 15.0746 7.20403C14.6777 7.5982 14.6777 8.23935 15.0746 8.63451C15.4716 9.02967 16.1147 9.02967 16.5117 8.63451C17.4713 7.68035 18.0003 6.40981 18.0003 5.05815C18.0003 3.70649 17.4712 2.4363 16.5116 1.48178L16.5116 1.48191Z"
        fill="black"
      />
    </svg>
  )
}

export default CopyIcon

import React from "react"
import { PrismicRichText } from "@prismicio/react"
import ImageAndText from "../ArticleContent/imageAndText.jsx"
import Image from "../ArticleContent/Image.jsx"

const ArticleSlices = ({ body }) => {
  return (
    <>
      {body &&
        body.map((slice, index) => {
          if (slice.slice_type === "rich_text") {
            const { content } = slice.primary
            return (
              <div key={index} className="rte rte--article my-10">
                <PrismicRichText field={content.richText} />
              </div>
            )
          }
          if (slice.slice_type === "image_and_text") {
            const { image, content } = slice.primary
            return (
              <div key={index} className="my-10">
                <ImageAndText image={image} text={content.richText} />
              </div>
            )
          }
          if (slice.slice_type === "image") {
            const { image } = slice.primary
            return (
              <div key={index} className="my-10">
                <Image image={image} />
              </div>
            )
          }
        })}
    </>
  )
}

export default ArticleSlices
